<template>
  <div class="container-fluid">
    <h3>Music Albums</h3>
    <div class="d-flex justify-content-around">
      <multiselect label="albumName"
                    :options="albums"
                    placeholder="Pick album..."
                    :searchable="true"
                    track-by="id"
                    v-model="album"
                    @select="selectedAlbum">
        <template slot="singleLabel" slot-scope="props">
          <img class="option__image"
              :src="props.option.thumb"
              :alt="props.option.albumName" />
          <span class="option__desc">
            <span class="option__title">
              <b>{{ props.option.albumName }}</b>
            </span>
            <br>
            <b>Order No:</b>
            <span class="option__small">
              {{ props.option.orderNo }}
            </span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <img class="option__image"
              :src="props.option.thumb"
              :alt="props.option.albumName" />
          <span class="option__desc">
            <span class="option__title">{{ props.option.albumName }}</span>
            <br>
            Order No:
            <span class="option__small">{{ props.option.orderNo }}</span>
          </span>
        </template>
      </multiselect>
    </div>
    <div class="m-2">
      <album-card :albumId="album.id"
                  v-if="album"
                  @update-selected-album="updateSelectedAlbum"
                  @delete-album-update="deleteAlbumUpdate"
                  :albumTotal="albums.length">
      </album-card>
    </div>
  </div>
</template>

<script>
  const AlbumCard = () => import('./AlbumCard.vue');
  const Multiselect = () => import('vue-multiselect');
  import { mapActions } from 'vuex';

  export default {
    name : 'AlbumList',
    data() {
      return {
        album  : null,
        albums : [],
      }
    },
    components : {
      AlbumCard,
      Multiselect,
    },
    methods : {
      ...mapActions({
        'getAlbumSongs'   : 'media/getAlbumSongs',
        'clearAlbumSongs' : 'media/clearAlbumSongs',
      }),

      /**
       * Get Album List
       */
      getAlbums() {
        this.$http.get('/api/albums', {
          params : {
            page    : 1,
            perPage : 500,
            sort    : '',
          },
        }).then(res => {
          this.albums = [];
          const alb = res.data.data;
          for (let i=0; i < alb.length; i++) {
            this.albums.push({
              id        : alb[i].item.mobile_album_id,
              albumName : alb[i].item.album_name,
              thumb     : alb[i].item.album_thumbnail,
              orderNo   : alb[i].item.order_no,
            });
          }
        });
      },

      /**
       * Selected album
       *
       * @param data
       */
      selectedAlbum(data) {
        this.album = data;
        this.clearAlbumSongs();
        this.getAlbumSongs(data.id);
      },

      /**
       * Update Selected Album Data
       *
       * @param album
       */
      updateSelectedAlbum(album) {
        this.getAlbums(); // update album list
        this.selectedAlbum(album);
        this.album = album;
        this.albumId = album.id;
      },

      /**
       * Update Album List after Update
       */
      deleteAlbumUpdate() {
        this.getAlbums();
        this.album = null;
      },
    },
    mounted() {
      this.getAlbums();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/album-list";
</style>